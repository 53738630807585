.TextIncoming {
    position: relative;
    text-align: center;
    padding: 0 0 180px 0;
    animation: 4s;
    animation-name: slidein;
}

@keyframes slidein {
    from {
        margin-left: 100%;
        width: 300% 
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}